@media screen and (max-width:1440px) {
    .logo {
        width: 400px;
    }
}

@media screen and (max-width:1024px) {
    .logo {
        width: 350px;
    }
}

@media screen and (max-width:768px) {
    .logo {
        width: 250px;
    }
}

@media screen and (max-width:426px) {
    .logo {
        width: 180px;
    }
}
@media screen and (max-width:325px) {
    .logo {
        width: 150px;
    }
}