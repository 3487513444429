@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes type {
    0% {
        width: 0;
    }

    1%,
    100% {
        border-right: 4px solid white;
    }
}


@keyframes weDothingsDifferently {
    0% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    20% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }

    40% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    60% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }

    80% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    100% {
        position: relative;
        left: -100px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }
}


@keyframes contactus {
    0% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    20% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }

    40% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    60% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }

    80% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 0;
        border-right: 4px solid white;
    }

    100% {
        position: relative;
        left: 0px;
        width: 0;
        opacity: 1;
        border-right: 4px solid white;
    }
}

@keyframes robot {
    0% {
        transform: translateY(-20px)
    }

    100% {
        transform: translateY(0px)
    }
}

.partners .swiper-slide {
    width: 25% !important;
}