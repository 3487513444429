.percentage {
  height: 6px;
  margin: 0;
  margin-top: 20px;
  padding: 0;
  width: 100%;
  transform: skewX(-20deg);
  transition: var(--loader-transition);
}

.percentage-fill {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #fff;
  transform-origin: left;
  transform: scaleX(0);
  transition: 500ms all linear;
}

@media screen and (min-width: 680px) {
  .percentage {
    height: 10px;
  }
}

.loader-bg.closed .percentage,
.loader-bg.removed .percentage {
  opacity: 0;
}
