@keyframes light {
    0% {
        opacity: 0.5;
        filter: blur(90px);
    }

    25% {
        opacity: 1;
        filter: blur(90px);
    }

    50% {
        opacity: 0.5;
        filter: blur(90px);
    }

    75% {
        opacity: 1;
        filter: blur(90px);
    }

    100% {
        opacity: 0.5;
        filter: blur(90px);
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {
        transform: translatey(0px);
    }
}

.ourservices .swiper-slide {
    width: 25%
}