@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  margin: 0;
  font-family: Extended Regular Neue Plak, Extended Light Neue Plak,
    Extended Bold Neue Plak, Condensed Neue Plak, Extended Thin Neue Plak,
    Extended Semi Bold Neue Plak, Condensed Neue Plak SemiBold, Inter,
    Bold Neue Plak, Condensed bold Neue Plak;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: black !important;
  user-select: none;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  position: relative;
  z-index: 10000;
}

*:not(.contact-textarea)::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* sr: screen reader -- for visually impaired people */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
