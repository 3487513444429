.contact-textarea {
  min-height: 20px !important;
}

.contact-textarea::-webkit-scrollbar {
  width: 8px;
  border-radius: 1rem;
  overflow: hidden;
}
.contact-textarea::-webkit-scrollbar-track {
  background: rgba(120, 120, 120, 0.5);
  border-radius: 1rem;
}
.contact-textarea::-webkit-scrollbar-thumb {
  background: rgba(120, 120, 120, 0.5);
  border-radius: 1rem;
}

/* .contact-field {
  box-shadow: 0px 1px white;
}
.contact-field:focus-visible {
  box-shadow: 0px 1px white;
} */
